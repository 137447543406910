<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col-md-6">
        <h2 class="text-dark d-inline-block m-0">All Admin Users</h2>
      </div>
      <div class="col-md-6">
        <div class="float-right">
          <router-link to="/create-admin" class="btn btn-primary btn-site mt-2"
            >Create Admin User</router-link
          >
        </div>
      </div>
    </div>
    <div>
      <SectionLoading v-if="loading" text="Admin Users loading..." />
      <div v-else>
        <div
          v-if="contents && contents.data && contents.data.length == 0"
          class="text-center my-5"
        >
          <p class="text-dark mt-5 pt-5">No Admin user added yet.</p>
          <router-link to="/create-admin" class="btn btn-primary btn-site mt-2"
            >Create Admin User</router-link
          >
        </div>
        <div v-else>
          <div class="card siteCard">
            <vs-tabs>
              <vs-tab label="All Users">
                <div class="table-responsive py-2">
                  <vs-table
                    id="div-with-loading"
                    max-items="10"
                    stripe
                    :data="contents.data"
                  >
                    <template slot="thead">
                      <vs-th class="text-dark" scope="col">S/N</vs-th>
                      <vs-th class="text-dark" scope="col">Date added</vs-th>
                      <vs-th class="text-dark" scope="col">Name</vs-th>
                      <vs-th class="text-dark" scope="col">Phone</vs-th>
                      <vs-th class="text-dark" scope="col">Email</vs-th>
                      <vs-th class="text-dark" scope="col">Role</vs-th>
                      <vs-th class="text-dark" scope="col">Last Active</vs-th>
                      <vs-th class="text-dark" scope="col">Action</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr :key="indextr" v-for="(user, indextr) in data">
                        <td>
                          <p class="text-dark mb-0 small">{{ indextr + 1 }}</p>
                        </td>
                        <vs-td :data="data[indextr].id">
                          <span class="text-dark">
                            {{
                              moment(
                                new Date(data[indextr].created_at)
                              ).fromNow()
                            }}</span
                          >
                        </vs-td>
                        <td>
                          <div class="font-weight-bold">
                            <p class="text-dark mb-0 title">
                              {{ user.name | capitalize }}
                            </p>
                          </div>
                        </td>

                        <td>
                          <p class="text-dark mb-0">
                            {{ user.phone }}
                          </p>
                        </td>
                        <td>
                          <p class="text-dark mb-0">{{ user.email }}</p>
                        </td>
                        <td>
                          <p class="text-dark mb-0">{{ user.user_type }}</p>
                        </td>
                        <vs-td :data="data[indextr].id">
                          <span class="text-dark">
                            {{
                              moment(
                                new Date(data[indextr].last_seen)
                              ).fromNow()
                            }}</span
                          >
                        </vs-td>
                        <td>
                          <router-link
                            :to="`/user/update-password/${user.uuid}`"
                            class="btn btn-primary btn-sm btn-site px-3"
                          >
                            Change Password
                          </router-link>
                          <button
                            @click="removeConfirmed(user.uuid)"
                            class="btn btn-outline-primary btn-sm px-3"
                          >
                            Remove
                          </button>
                        </td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <!-- <vs-pagination
              v-if="contents"
              class="mt-4"
              :total="Math.ceil(contents.total / 20)"
              v-model="table_options.current_page"
            ></vs-pagination> -->
                  <div class="mt-4" v-if="contents && contents.data">
                    <nav aria-label="Page navigation ">
                      <ul
                        class="pagination px-4"
                        style="justify-content: right"
                      >
                        <li class="page-item">
                          <a
                            class="page-link"
                            :disabled="true"
                            href="javascript:;"
                            ><vs-icon icon="chevron_left"></vs-icon
                          ></a>
                        </li>
                        <li
                          :class="`page-item ${
                            contents.current_page == count ? 'active' : ''
                          }`"
                          v-for="(count, index) in Math.ceil(
                            contents.total / 20
                          )"
                          :key="index"
                        >
                          <a class="page-link" href="javascript:;">{{
                            count
                          }}</a>
                        </li>
                        <li class="page-item">
                          <a
                            class="page-link"
                            :disabled="true"
                            href="javascript:;"
                            ><vs-icon icon="chevron_right"></vs-icon
                          ></a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </vs-tab>

              <vs-tab label="Deleted Users">
                <div class="table-responsive py-2">
                  <vs-table
                    v-if="contentsDeleted"
                    id="div-with-loading"
                    max-items="10"
                    stripe
                    :data="contentsDeleted"
                  >
                    <template slot="thead">
                      <vs-th class="text-dark" scope="col">S/N</vs-th>
                      <vs-th class="text-dark" scope="col">Date added</vs-th>
                      <vs-th class="text-dark" scope="col">Name</vs-th>
                      <vs-th class="text-dark" scope="col">Phone</vs-th>
                      <vs-th class="text-dark" scope="col">Email</vs-th>
                      <vs-th class="text-dark" scope="col">Role</vs-th>
                      <vs-th class="text-dark" scope="col">Last Active</vs-th>
                      <vs-th class="text-dark" scope="col">Action</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr :key="indextr" v-for="(user, indextr) in data">
                        <td>
                          <p class="text-dark mb-0 small">{{ indextr + 1 }}</p>
                        </td>
                        <vs-td :data="data[indextr].id">
                          <span class="text-dark">
                            {{
                              moment(
                                new Date(data[indextr].created_at)
                              ).fromNow()
                            }}</span
                          >
                        </vs-td>
                        <td>
                          <div class="font-weight-bold">
                            <p class="text-dark mb-0 title">
                              {{ user.name | capitalize }}
                            </p>
                          </div>
                        </td>

                        <td>
                          <p class="text-dark mb-0">
                            {{ user.phone }}
                          </p>
                        </td>
                        <td>
                          <p class="text-dark mb-0">{{ user.email }}</p>
                        </td>
                        <td>
                          <p class="text-dark mb-0">{{ user.user_type }}</p>
                        </td>
                        <vs-td :data="data[indextr].id">
                          <span class="text-dark">
                            {{
                              moment(
                                new Date(data[indextr].last_seen)
                              ).fromNow()
                            }}</span
                          >
                        </vs-td>
                        <td>
                          <button
                            @click="restoreUser(user.uuid)"
                            class="btn btn-outline-primary btn-sm px-3"
                          >
                            Restore user
                          </button>
                        </td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusIcon } from "vue-feather-icons";
import SectionLoading from "../../../components/SectionLoading";
export default {
  name: "Home",
  components: {
    PlusIcon,
    SectionLoading,
  },
  data() {
    return {
      loading: false,
      contents: [],
      contentsDeleted: [],
      list: "tile",
      listStyles: [
        { text: "Tile View", value: "tile" },
        { text: "List View", value: "list" },
      ],
      table_options: {
        current_page: 1,
      },
    };
  },
  watch: {
    "table_options.current_page": function () {
      this.getContents(true);
    },
  },
  mounted() {
    this.getBl();
    this.getDeletedUsers();
  },
  methods: {
    restoreUser(id) {},
    removeConfirmed(id) {
      this.$vs.loading();
      let fetch = {
        path: `admin/staff/delete/${id}`,
        data: {},
      };
      this.$store
        .dispatch("deleteRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "User Delete",
            "User has been deleted successfully",
            this.$toastPosition
          );

          this.$vs.loading.close();
          this.getBl();
          this.getDeletedUsers();
        })
        .catch((err) => {
          this.$vs.loading.close();

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to delete User",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Something went wrong",
              "Unable to delete user",
              this.$toastPosition
            );
          }
        });
    },
    getBl() {
      this.getContents(false);
    },

    getDeletedUsers() {
      this.loading = true;
      let fetch = {
        path: `admin/users/soft-delete-users`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          if (resp.data && resp.data.data) {
            this.contentsDeleted = resp.data.data;
          }
       
          this.loading = false;
        })
        .catch((err) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Users",
              text: "Unable to get list of users",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    getContents(divLoad) {
      if (divLoad) {
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
      } else {
        this.loading = true;
      }
      let fetch = {
        path: `/admin/staff?type=admin&page${this.table_options.current_page}`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.contents = resp.data.data;

          if (divLoad) {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Users",
              text: "Unable to get list of users",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    playAudio(source) {
      this.$store.commit("audioPlay", {
        status: true,
        source,
      });
    },
  },
};
</script>
